import { parseWithZod } from "@conform-to/zod";
import { UserAppRole } from "@prisma/client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

import { APP_ROLES, AppRole, TICKET_EVENT_TYPES, TicketEventType } from "~/lib/constants";

export function cn(...inputs: Array<ClassValue>) {
  return twMerge(clsx(inputs));
}

export async function parseFormSubmission<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const formData = await args.request.formData();
  const submission = parseWithZod<T>(formData, { schema: args.schema });
  return submission;
}

export async function parseFormData<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const formData = await args.request.formData();
  const result = args.schema.safeParse(Object.fromEntries(formData));
  return result as z.SafeParseReturnType<FormData, z.infer<T>>;
}

export function parseSearchParams<T extends z.ZodTypeAny>(args: { request: Request; schema: T }) {
  const params = new URLSearchParams(new URL(args.request.url).searchParams);
  const data = args.schema.parse(Object.fromEntries(params)) as z.infer<T>;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return data;
}

export function getTicketEventDetails(type: TicketEventType) {
  switch (type) {
    case TICKET_EVENT_TYPES.CREATED: {
      return "created the ticket";
    }
    case TICKET_EVENT_TYPES.ARCHIVED: {
      return "archived the ticket";
    }
    case TICKET_EVENT_TYPES.DESCRIPTION: {
      return "updated the description";
    }
    case TICKET_EVENT_TYPES.USER_FOLLOWED: {
      return "started following the ticket";
    }
    case TICKET_EVENT_TYPES.USER_UNFOLLOWED: {
      return "stopped following the ticket";
    }
    case TICKET_EVENT_TYPES.TYPE_CHANGE: {
      return "changed the type to";
    }
    case TICKET_EVENT_TYPES.STATUS_CHANGE: {
      return "changed the status to";
    }
    case TICKET_EVENT_TYPES.PRIORITY_CHANGE: {
      return "changed the priority to";
    }
    case TICKET_EVENT_TYPES.ASSIGNMENT_CHANGE: {
      return "assigned the ticket to";
    }
    case TICKET_EVENT_TYPES.UNASSIGNED: {
      return "unassigned the ticket";
    }
    case TICKET_EVENT_TYPES.ASSET_CHANGE: {
      return "updated the asset to";
    }
    case TICKET_EVENT_TYPES.LOCATION_CHANGE: {
      return "updated the location to";
    }
    default: {
      return "performed an unknown action";
    }
  }
}

export function capitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.toLowerCase().slice(1);
}

export const CheckboxSchema = z
  .string()
  .transform((val) => val === "on")
  .or(z.undefined());

export const CurrencySchema = z.preprocess(
  (v) => (typeof v === "string" && v[0] === "$" ? v.slice(1) : v),
  z.coerce
    .number({ invalid_type_error: "Must be a number", required_error: "Amount required" })
    .multipleOf(0.01, { message: "Must be multiple of $0.01" })
    .nonnegative({ message: "Must be greater than $0.00" })
    .max(99_999, { message: "Must be less than $100,000" })
    .transform((dollars) => Math.round(dollars * 100)),
);

export function formatCurrency(value: number, decimals?: 0 | 2) {
  const decimalPlaces = decimals ? decimals : value % 1 !== 0 ? 2 : 0;
  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces,
  }).format(value);

  return formattedValue;
}

export function formatCentsAsDollars(value: number | null | undefined, decimals: 0 | 2 = 2) {
  if (!value) {
    return formatCurrency(0, decimals);
  }
  return formatCurrency(value / 100, decimals);
}

export function normalizeEnum(value: string) {
  const wordsToKeepLowercase = ["a", "an", "the", "and", "but", "or", "for", "of"];

  return value
    .split(/[_\s]+/)
    .map((word, index) => {
      if (index === 0 || !wordsToKeepLowercase.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(" ");
}

export function userHasRoles(roles: Array<UserAppRole>, requiredRoles: Array<AppRole>) {
  // Admins have all roles
  if (roles.some((r) => r.appRoleId === APP_ROLES.ADMINISTRATOR)) {
    return true;
  }

  return roles.some((r) => requiredRoles.includes(r.appRoleId));
}

export function userIsAdmin(roles: Array<UserAppRole>) {
  return userHasRoles(roles, [APP_ROLES.ADMINISTRATOR]);
}

export function userIsAtLeastPortalUser(roles: Array<UserAppRole>) {
  return userHasRoles(roles, [APP_ROLES.ADMINISTRATOR, APP_ROLES.PORTAL_USER]);
}

export function wait(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
