export const COMPANY_DOMAIN = "turns.com" as const;

export const TICKET_STATUS_TYPES = {
  NEW: "NEW",
  IN_PROGRESS: "IN_PROGRESS",
  CLOSED: "CLOSED",
} as const;
export type TicketStatusType = (typeof TICKET_STATUS_TYPES)[keyof typeof TICKET_STATUS_TYPES];

export const DEFAULT_TICKET_STATUSES = {
  NEW: "New",
  IN_PROGRESS: "In Progress",
  RESOLVED: "Resolved",
} as const;
export type DefaultTicketStatus = (typeof DEFAULT_TICKET_STATUSES)[keyof typeof DEFAULT_TICKET_STATUSES];

export const TICKET_PRIORITIES = {
  LOW: "LOW",
  MEDIUM: "MEDIUM",
  HIGH: "HIGH",
} as const;
export type TicketPriority = (typeof TICKET_PRIORITIES)[keyof typeof TICKET_PRIORITIES];

export const TICKET_EVENT_TYPES = {
  CREATED: "CREATED",
  ARCHIVED: "ARCHIVED",
  UNASSIGNED: "UNASSIGNED",
  DESCRIPTION: "DESCRIPTION",
  USER_FOLLOWED: "USER_FOLLOWED",
  USER_UNFOLLOWED: "USER_UNFOLLOWED",
  TYPE_CHANGE: "TYPE_CHANGE",
  STATUS_CHANGE: "STATUS_CHANGE",
  PRIORITY_CHANGE: "PRIORITY_CHANGE",
  ASSIGNMENT_CHANGE: "ASSIGNMENT_CHANGE",
  ASSET_CHANGE: "ASSET_CHANGE",
  LOCATION_CHANGE: "LOCATION_CHANGE",
} as const;
export type TicketEventType = (typeof TICKET_EVENT_TYPES)[keyof typeof TICKET_EVENT_TYPES];

export const APP_ROLES = {
  // See "Administrator" App Role
  ADMINISTRATOR: "152b75e0-5154-4c79-8908-0e45c84edf5b",
  // See "Administrator" App Role
  PORTAL_USER: "ed6de385-7ce0-4240-9a09-ad6efd9db515",
  // See "Ticket Reader" App Role
  TICKET_READER: "a881dfba-adde-471b-bff5-fd1584a0192e",
  // See "Ticket Contributor" App Role
  TICKET_CONTRIBUTOR: "d45c44a8-c1d8-482c-b7ee-5d4109d3e0bf",
  DEFAULT: "00000000-0000-0000-0000-000000000000",
};
export type AppRole = (typeof APP_ROLES)[keyof typeof APP_ROLES];

export const STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
] as const;
